import { event } from "lib/gtag"
import Button from "components/UI/Button"
import { MoviePlayIcon } from "assets/icons"
import Banner from "components/Banner"
import { ApiPath } from "lib/endpoint"
import useSwr from "swr"

const HomepageBanner = () => {
  const getJson = (url) => fetch(url).then((data) => data.json())

  const url = ApiPath + "/banner"
  const { data, error } = useSwr(url, getJson)

  if (data) {
    var banner
    if (data.length > 0) {
      banner = data[Math.floor(Math.random() * data.length)]
    } else {
      return <></>
      // banner = {
      //   caption_1: "Support creators create.",
      //   caption_2: "10,000+ surf videos in your pocket.",
      //   poster_url: "",
      //   mp4_url: "/img/bannerBackground.mp4",
      //   webm_url: "/img/bannerBackground.webm",
      //   mobile_url: "/img/bannerBackground.mp4",
      //   showDownload: true,
      // }
    }

    var bannerData = {
      title: banner.caption_1,
      subtitle: banner.caption_2,
      videoSources: {
        poster: banner.poster_url,
        mp4: banner.mp4_url,
        webm: banner.webm_url,
        mobile: banner.mobile_url,
      },
      link: banner.web_url,
      targetBlank: banner.target_blank == 1 ? "_blank" : "",
      button: {
        label:
          banner.button_text == null || banner.button_text == ""
            ? "WATCH NOW"
            : banner.button_text,
        icon: <MoviePlayIcon />,
      },
    }

    return (
      <Banner
        title={bannerData.title}
        subtitle={bannerData.subtitle}
        videoSources={{
          poster: bannerData.videoSources.poster,
          mp4: bannerData.videoSources.mp4,
          webm: bannerData.videoSources.webm,
          mobile: bannerData.videoSources.mobile,
        }}
        link={bannerData.link}
        target={bannerData.targetBlank}
        onClick={() => handleBunnerClick(bannerData.title)}
        showDownload={bannerData.showDownload}>
        {bannerData.button && (
          <div className="flex mt-6">
            <Button
              className="md:hidden"
              label={bannerData.button.label}
              iconAfter={bannerData.button.icon}
              onClick={() => handleButtonClick(bannerData.title)}
              variant="contained"
              theme="light"
            />
            <Button
              className="hidden md:flex"
              label={bannerData.button.label}
              iconAfter={bannerData.button.icon}
              onClick={() => handleButtonClick(bannerData.title)}
              variant="contained"
              theme="light"
              size="regular"
            />
          </div>
        )}
      </Banner>
    )
  } else {
    return <></>
  }
}

export default HomepageBanner
